// msalConfig.js
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "2bd91fee-857c-47ad-9143-3510cfa00c1d",
    authority:
      "https://login.microsoftonline.com/6df7667f-98b8-4e34-9f3c-4cb97735b3c6",
    redirectUri: "https://test.falgun-garmenting.com/mailbox",
    // redirectUri: "http://localhost:3000/mailbox",
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
