import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import Dropdown from "react-bootstrap/Dropdown";

export default function PurchaseOrders(props) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="7"
          viewBox="0 0 9 7"
        >
          <path
            id="Polygon_60"
            data-name="Polygon 60"
            d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
            transform="translate(9 7) rotate(180)"
            fill="#707070"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "none",
      border: "none",
      minHeight: "21px",
      fontSize: "15px",
      height: "21px",
      background: "#ECECEC",
      lineHeight: "100%",
      boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.18)",
      boxShadow: state.isFocused ? "" : "",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "21px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "21px",
    }),
  };

  const [workOrders, setWorkOrders] = useState(
    Array.from({ length: 50 }, (_, index) => {
      const serial = String(index + 1).padStart(2, "0");
      return { value: `WONXF1JM${serial}`, label: `WONXF1JM${serial}` };
    })
  );

  useEffect(async () => {
    props.setHeaderData({
      pageName: "Purchase Orders",
      isNewButton: true,
      newButtonLink: "",
      newButtonText: "New PO",
      isInnerSearch: true,
      innerSearchValue: "",
      isDropdown: true,
      DropdownMenu: [
        { title: "Purchase Orders", url: "/purchase-orders" },
        { title: "Work Orders", url: "/work-orders" },
        { title: "Purchase Contracts", url: "/purchase-contracts" },
      ],
    });
  }, []);

  return (
    <div className="purchase_order_page">
      <div
        className="row d-grid"
        style={{ gridTemplateColumns: "13% 37% 50%" }}
      >
        <div className="col">
          <div className="purchase_sidebar">
            <div className="email-section">
              <div className="folder_name">Department</div>
              <ul>
                <li>
                  <button className="active">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="email-section">
              <div className="folder_name">Purchase Contract</div>
              <ul>
                <li>
                  <button className="active">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
                <li>
                  <button className="">
                    Folder Name <span>20</span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="email-section">
              <div className="folder_name">Styles</div>
              <Select
                className="select_wo"
                placeholder="Search Or Select"
                options={workOrders}
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="purchase_list">
            <div className="purchase_list_header d-flex justify-content-between">
              <div className="purchase_header_left">
                <div className="title">
                  <input type="checkbox" /> Purchase Orders
                </div>
                <div className="buttons_group">
                  <button>All</button>
                  <button>Urgent</button>
                  <button>Unassigned WO</button>
                </div>
              </div>
              <div className="purchase_header_left">
                <span className="toggleSelect" style={{ cursor: "pointer" }}>
                  <img
                    style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                    src={require("../assets/images/icons/T1-W.png").default}
                    alt="Logo"
                  />
                </span>

                <Dropdown className="purchase_filter_dropdown">
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                  >
                    <img
                      style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                      src={
                        require("../assets/images/icons/Settings-W.png").default
                      }
                      alt="Logo"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Date</Dropdown.Item>
                    <Dropdown.Item>From</Dropdown.Item>
                    <Dropdown.Item>Subject</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />{" "}
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>{" "}
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <div className="mail_text">
                  <input type="checkbox" />
                  <i className="fa fa-play text-muted mx-2"></i>
                  PONXT01245
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
                <div className="mail_text">
                  <span className="step_border"></span>
                  TEXT HERE
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="purchase_details">
            <div className="details_header d-flex justify-content-between">
              <div className="">
                <img
                  style={{ width: "30px", marginRight: "12px" }} // Corrected 'widows' to 'width'
                  src={require("../assets/images/logos/logo-short.png").default}
                  alt="Logo"
                />
                <span className="purchase_text"> Purchase Order</span>
              </div>
              <div className="left_side d-flex gap_10">
                <div className="wo_number">
                  <Select
                    className="select_wo"
                    placeholder="Search or Select WO"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="buttons_group">
                  <button>Submit</button>
                </div>
              </div>
            </div>
            <div className="details_area_scroller">
              <div className="row">
                <div className="col-3">
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="PO"
                    styles={customStyles}
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Buyer"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Style"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Season"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="PC/LC"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Service Type"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="Item"
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Shipment Method"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Currency"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="col-3">
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="Price"
                  />
                </div>
                <div className="col-3">
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="Qty"
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Vendor"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Payment Term"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div className="col-3">
                  <input
                    className="form-control"
                    type="date"
                    name="title"
                    placeholder="Issued Date"
                  />
                </div>
                <div className="col-3">
                  <input
                    className="form-control"
                    type="date"
                    name="title"
                    placeholder="Ex Fty Date"
                  />
                </div>
                <div className="col-3">
                  <input
                    className="form-control"
                    type="date"
                    name="title"
                    placeholder="Delivery Date"
                  />
                </div>
                <div className="col-3">
                  <input
                    className="form-control"
                    type="date"
                    name="title"
                    placeholder="Value"
                  />
                </div>

                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Product Type"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="col-3">
                  <Select
                    className="select_wo"
                    placeholder="Color"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
                <div className="col-3">
                  <Select
                    isMulti
                    className="select_wo"
                    placeholder="Size"
                    options={workOrders}
                    //   styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <h6>Order Summary</h6>
                <div className="col-lg-12">
                  <table className="table table-bordered po_list_table">
                    <thead>
                      <th>#</th>
                      <th>Item</th>
                      <th>Style</th>
                      <th>Color</th>
                      <th>Size</th>
                      <th>Qty(PCS)</th>
                      <th>Unit Price ($)</th>
                      <th>Amount ($)</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>N96472</td>
                        <td>Menswear 20 PRT Belt FGray ST</td>
                        <td>Gray</td>
                        <td>30</td>
                        <td>200</td>
                        <td>7.4</td>
                        <td>1480.00</td>
                      </tr>
                      <tr>
                        <td className="text-center" colSpan={7}>
                          TOTAL
                        </td>
                        <td colSpan={1}>10360.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="terms_details_area">
                    <div className="terms_header d-flex justify-content-between">
                      <div className="text">Terms & Conditions</div>
                      <button>Request Rivision</button>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Mode of Shipment:</div>
                      <div className="col-lg-9">By Sea </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Payment terms:</div>
                      <div className="col-lg-9">
                        By TT, payment will be made within 15 working days of
                        shipment date.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Payee Bank:</div>
                      <div className="col-lg-9">
                        WELLS FARGO BANK, N. A, (New York International Branch)
                        New York, NY, USA
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Shipper Bank:</div>
                      <div className="col-lg-9">
                        EXPORT IMPORT BANK OF BANGLADSH LIMITED, AGRABAD BRANCH
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Porr Of Loading:</div>
                      <div className="col-lg-9">Chittagong, Bangladesh</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Port of Destination:</div>
                      <div className="col-lg-9">New York, USA </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Date of Shipment:</div>
                      <div className="col-lg-9">15-Aug-24</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Date of Expiry:</div>
                      <div className="col-lg-9">14-Apr-25</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Partial shipment :</div>
                      <div className="col-lg-9">Allowed </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Defective Allowance: :</div>
                      <div className="col-lg-9">
                        0.5% deduction is allowed in the commercial invoice
                        within reason.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3">Documents required::</div>
                      <div className="col-lg-9">Commercial Invoice</div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-12">
                  <div className="row signature_part">
                    <div className="col-lg-3 border">Merchant: Anik Das </div>
                    <div className="col-lg-3 border">FG ID: </div>
                    <div className="col-lg-3 border">FG Pass: </div>
                    <div className="col-lg-3 border">Buyer Signatory:</div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
