import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../elements/Spinner";
import MailFolders from "../../elements/mail/MailFolders";
import SentMail from "../../elements/mail/SentMail";
import MailDetails from "../../elements/mail/MailDetails";
import MailList from "../../elements/mail/MailList";
import MailDirection from "../../elements/mail/MailDirection";

export default function Mailbox(props) {
  useEffect(async () => {
    props.setHeaderData({
      pageName: "Mail",
      isModalButton: true,
      modalButtonRef: "composeSection",
      isNewButton: false,
      newButtonLink: "",
      newButtonText: "New mail",
      isInnerSearch: true,
      innerSearchValue: "",
      isDropdown: false,
      DropdownMenu: [],
      isBackBtn: true,
    });
  }, []);
  return (
    <>
      <div className="email_layout">
        <MailFolders {...props} />
        <MailList {...props} />

        <div
          className={`email-detail ${props.resizeToggle ? "" : "expanded"} ${
            props.extendDetailsToggle ? "extend_full" : ""
          }`}

          // className={
          //   props.resizeToggle ? "email-detail" : "email-detail expanded"
          // }
        >
          {props.isComposing ? (
            <SentMail {...props} />
          ) : props.mailID ? (
            <MailDetails {...props} />
          ) : (
            <MailDirection {...props} />
          )}
        </div>
      </div>
    </>
  );
}
