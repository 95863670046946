import React, { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import api from "services/api";
import { Badge } from "react-bootstrap";
import IntlDateTime from "./IntlDateTime";
import InnerSearch from "./InnerSearch";
import HeaderDropdown from "./HeaderDropdown";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import SignInButton from "./mail/SignInButton";
import SignOutButton from "./mail/SignOutButton";
import swal from "sweetalert";

const Header = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const canGoBack = history.length > 2;

  // Function to go back
  const handleBackClick = () => {
    history.goBack();
  };

  const markAsRead = async (id) => {
    try {
      const response = await api.post("/notifications-read", {
        id: id,
      });
      if (response.status === 200 && response.data) {
        // After marking the notification as read, you may choose to update the notifications list
        props.setCallNotifications(true);

        // Delay for 500 milliseconds before setting the notification back to false
        setTimeout(() => {
          props.setCallNotifications(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  //mail delete,read,unread functions

  const { instance, accounts } = useMsal();

  // Memoize access token to avoid acquiring it unnecessarily
  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);

  const bulkDelete = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmDelete) => {
      if (confirmDelete) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: "deleteditems",
                }),
              }
            );

            if (moveResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error(
                "Failed to move email to Deleted Items for ID:",
                id
              );
            }
          } catch (error) {
            console.error("Error deleting email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);

  const bulkRestore = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to restore selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmRestore) => {
      if (confirmRestore) {
        const accessToken = await getAccessToken();
        let restoredFolder = null;

        // Check if "Restored" folder exists, create if necessary
        const folderCheckResponse = await fetch(
          `https://graph.microsoft.com/v1.0/me/mailFolders`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const foldersData = await folderCheckResponse.json();
        restoredFolder = foldersData.value.find(
          (folder) => folder.displayName === "Restored"
        );

        if (!restoredFolder) {
          const createFolderResponse = await fetch(
            `https://graph.microsoft.com/v1.0/me/mailFolders`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                displayName: "Restored",
              }),
            }
          );
          restoredFolder = await createFolderResponse.json();
        }

        for (const id of props.selectedMailIds) {
          try {
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: restoredFolder.id,
                }),
              }
            );

            if (moveResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to restore email ID:", id);
            }
          } catch (error) {
            console.error("Error restoring email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkPermanentDelete = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to permanently delete selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmDelete) => {
      if (confirmDelete) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const deleteResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (deleteResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to delete email ID:", id);
            }
          } catch (error) {
            console.error("Error deleting email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkRead = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to mark selected emails as read?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmRead) => {
      if (confirmRead) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const readResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  isRead: true,
                }),
              }
            );

            if (readResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.map((email) =>
                  email.id === id ? { ...email, isRead: true } : email
                )
              );
            } else {
              console.error("Failed to mark email as read for ID:", id);
            }
          } catch (error) {
            console.error("Error marking email as read ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkUnread = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to mark selected emails as unread?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmUnread) => {
      if (confirmUnread) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const unreadResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  isRead: false,
                }),
              }
            );

            if (unreadResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.map((email) =>
                  email.id === id ? { ...email, isRead: false } : email
                )
              );
            } else {
              console.error("Failed to mark email as unread for ID:", id);
            }
          } catch (error) {
            console.error("Error marking email as unread ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);

  return (
    <header className="header_V1">
      <div className="header_content">
        <div className="header_left_menus">
          {/* {props.headerData?.isBackBtn && (
            <>
              {canGoBack && (
                <button className="backBtn" onClick={handleBackClick}>
                  <i className="fa fa-chevron-left"></i>
                </button>
              )}
            </>
          )} */}

          <div className="header_page_title">{props.headerData?.pageName}</div>
          {props.headerData?.isModalButton === true && (
            <>
              {props.headerData?.modalButtonRef === "buyerModal" && (
                <button
                  onClick={() => {
                    props.setBuyerModal(true);
                  }}
                  className="btn new_btn"
                >
                  {props.headerData?.newButtonText}
                </button>
              )}
              {props.headerData?.modalButtonRef === "composeSection" && (
                <button
                  onClick={() => {
                    props.setIsComposing(true);
                    props.setMailSendType("Send");
                  }}
                  className="btn new_btn"
                >
                  {props.headerData?.newButtonText}
                </button>
              )}
            </>
          )}
          {props.headerData?.isNewButton === true && (
            <Link to={props.headerData?.newButtonLink} className="btn new_btn">
              {props.headerData?.newButtonText}
            </Link>
          )}
          {props.headerData?.isDropdown === true && (
            <HeaderDropdown {...props} />
          )}
          {props.headerData.isInnerSearch === true && (
            <InnerSearch {...props} />
          )}

          {props.selectedMailIds.length > 0 && (
            <div className="buttons_group">
              {props.mailFolder.folderName === "Deleted Items" ? (
                <>
                  <button className="text-sucess" onClick={bulkRestore}>
                    Restore
                  </button>
                  <button className="text-danger" onClick={bulkPermanentDelete}>
                    Trash
                  </button>
                </>
              ) : (
                <button className="text-danger" onClick={bulkDelete}>
                  Delete
                </button>
              )}

              <button onClick={bulkRead}>Read</button>
              <button onClick={bulkUnread}>Unread</button>
            </div>
          )}
        </div>
        <div className="header_middle_content">
          
        </div>

        <div className="header_right_menus ms-auto">
          <IntlDateTime />
          <div className="item">
            <a
              href="https://web.whatsapp.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>

          {/* <div className="item">
            <Link to="/messages">
              <i className="fa fa-envelope"></i>
            </Link>
          </div> */}
          <div className="item dropdown">
            <Link className=" dropdown-toggle" data-bs-toggle="dropdown">
              <Badge
                style={{ position: "absolute", top: "-10px", right: "-10px" }}
                bg="danger"
              >
                {props.unreadNotifications.length > 0
                  ? props.unreadNotifications.length
                  : ""}
              </Badge>
              <i className="fa fa-bell"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              {props.unreadNotifications.map((item, index) => (
                <>
                  <Link
                    to={item.url}
                    key={index}
                    onClick={() => markAsRead(item.id)}
                    className="dropdown-item"
                  >
                    <h6 className="fw-normal mb-0">{item.title}</h6>
                    <p style={{ fontSize: "13px" }}>{item.description}</p>
                    <small>
                      {moment(item.created_at).format("MMMM Do YYYY, h:mm A")}
                    </small>
                  </Link>
                  <hr className="dropdown-divider" />
                </>
              ))}

              <a href="#" className="dropdown-item text-center">
                See all notifications
              </a>
            </div>
          </div>
          <div className="item">
            <Link className="#">
              <i className="fa fa-search"></i>
            </Link>
          </div>

          <div className="item dropdown">
            <Link className=" dropdown-toggle" data-bs-toggle="dropdown">
              <Badge
                style={{ position: "absolute", top: "-10px", right: "-10px" }}
                bg="danger"
              >
                {props.unreadNotifications.length > 0
                  ? props.unreadNotifications.length
                  : ""}
              </Badge>
              <i className="fa fa-cog"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              {/* <Link to="#" className="dropdown-item">
                <h6 className="fw-normal mb-0">Email Configuration</h6>
              </Link> */}
              {isAuthenticated ? <SignOutButton /> : <SignInButton />}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
