// authConfig.js
export const loginRequest = {
  scopes: [
    "User.Read",
    "Mail.Read",
    "Mail.Send",
    "Contacts.Read",
    "Contacts.ReadWrite",
  ],
};

// authConfig.js
// export const loginRequest = {
//   scopes: [
//     // User profile information
//     "User.Read", // Sign-in and read user profile
//     "User.ReadWrite", // Read and write user profile
//     "User.ReadBasic.All", // Read all users' basic profiles

//     // Mail services
//     "Mail.Read", // Read user's mail
//     "Mail.ReadWrite", // Read and write access to user's mail
//     "Mail.Send", // Send mail as the user
//     "MailboxSettings.Read", // Read user's mailbox settings
//     "MailboxSettings.ReadWrite", // Read and write user's mailbox settings

//     // Contacts services
//     "Contacts.Read", // Read user's contacts
//     "Contacts.ReadWrite", // Create, read, update, and delete user's contacts

//     // Calendar services
//     "Calendars.Read", // Read user's calendar events
//     "Calendars.ReadWrite", // Read and write user's calendar events

//     // Files (OneDrive) services
//     "Files.Read", // Read user's files
//     "Files.ReadWrite", // Read and write user's files
//     "Files.Read.All", // Read all files user can access
//     "Files.ReadWrite.All", // Read and write all files user can access
//     "Files.ReadWrite.AppFolder", // Read and write to the application's folder

//     // Group services
//     "Group.Read.All", // Read all groups
//     "Group.ReadWrite.All", // Read and write all groups

//     // Notes (OneNote) services
//     "Notes.Read", // Read user's OneNote notebooks
//     "Notes.ReadWrite", // Read and write user's OneNote notebooks

//     // People services
//     "People.Read", // Read user's people and relationships

//     // Tasks (Planner) services
//     "Tasks.Read", // Read user's tasks
//     "Tasks.ReadWrite", // Read and write user's tasks

//     // Sites (SharePoint) services
//     "Sites.Read.All", // Read items in all site collections
//     "Sites.ReadWrite.All", // Read and write items in all site collections

//     // Teamwork services
//     "Teamwork.Migrate.All", // Migrate users' teamwork data

//     // User Activity services
//     "UserActivity.ReadWrite.CreatedByApp", // Write and read user activities created by this app

//     // Offline access
//     "offline_access", // Maintain access to data you have given access to
//   ],
// };
