import React from "react";

class SpinnerComponent extends React.Component {
  state = {
    loading: true,
  };
  render() {
    return (
      <div className="spinner_overlay">
        {/* <img
          style={{ height: "50vh", width: "50vw" }}
          src={require("assets/images/logos/page-loder-new.gif").default}
        /> */}
      </div>
    );
  }
}

export default SpinnerComponent;
