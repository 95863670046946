// Sidebar.js
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { hasPermission } from "../routes/permissions/CheckPermissions";
import {
  defaultConfig,
  rolesPermissions,
} from "../routes/permissions/PermissionsConfig";
import AppContext from "contexts/AppContext";
import auth from "services/auth";

export default function Sidebar(props) {
  const userData = props.userData;
  const location = useLocation();
  const pathname = location.pathname;
  const { updateUserObj } = useContext(AppContext);

  const isAuthenticated = props.isAuthenticated;
  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  return (
    <div
      className={`sidebar falgun-sidebar ${
        props.sidebar ? "bg-black" : "open bg-black"
      }`}
    >
      <nav className="navbar bg-black" style={{ display: "block" }}>
        <div className="logo_area">
          <a href="/dashboard" className="navbar-brand margin-auto text-center">
            <img
              src={require("../assets/images/logos/logo-short.png").default}
              alt="Logo"
            />
          </a>
        </div>
        <div className="common_menus">
          <ul>
            {defaultConfig.map(
              (item, index) =>
                hasPermission(userData, item.path) && (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={pathname === item.path ? "active" : ""}
                    >
                      {item.label}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </div>

        <div className="common_menus">
          <ul>
            {Object.keys(rolesPermissions).map(
              (department, index) =>
                userData?.department_title === department &&
                Object.keys(rolesPermissions[department]).map(
                  (designation, idx) =>
                    userData?.designation_title === designation &&
                    rolesPermissions[department][designation].map(
                      (item, subIndex) =>
                        hasPermission(userData, item.path) && (
                          <li key={`${index}-${idx}-${subIndex}`}>
                            <Link
                              className={pathname === item.path ? "active" : ""}
                              to={item.path}
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                    )
                )
            )}
          </ul>
        </div>

        <div className="user_personal_menus">
          <ul>
            <li>
              <Link
                className={pathname === "/profile" ? "active" : ""}
                to="/profile"
                title="Profile"
              >
                <i className="fa fa-user"></i>
              </Link>
            </li>
            <li>
              <Link title="Log Out" to="#" onClick={logout}>
                <i class="fa fa-power-off"></i>
              </Link>
            </li>
          </ul>

          <img
            className="profile_image_menu"
            src={props.userData?.profile_picture}
            alt=""
          />
        </div>
      </nav>
    </div>
  );
}
