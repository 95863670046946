import React, { useState, useEffect, useRef } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import ls from "services/ls";
import Select, { components } from "react-select";

export default function MailFolders(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [folders, setFolders] = useState([]);
  const initialFolderSet = useRef(false); // Ref to track if the initial folder has been set

  const folderOrder = [
    "Inbox",
    "Drafts",
    "Sent Items",
    "Deleted Items",
    "Archive",
    "Conversation History",
    "Junk Email",
    "Outbox",
    "RSS Feeds",
  ];

  useEffect(() => {
    const getMailFolders = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          const foldersResponse = await fetch(
            "https://graph.microsoft.com/v1.0/me/mailFolders?$select=displayName,unreadItemCount",
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (foldersResponse.ok) {
            const data = await foldersResponse.json();

            // // Sort the folders based on the predefined order
            // const sortedFolders = data.value.sort((a, b) => {
            //   const indexA = folderOrder.indexOf(a.displayName);
            //   const indexB = folderOrder.indexOf(b.displayName);

            //   if (indexA === -1) return 1;
            //   if (indexB === -1) return -1;
            //   return indexA - indexB;
            // });

            setFolders(data.value);

            // Set the initial folder only if it hasn't been set yet
            if (!initialFolderSet.current) {
              const inboxFolder = data.value.find(
                (folder) => folder.displayName === "Inbox"
              );
              if (inboxFolder) {
                props.setMailFolder({
                  folderName: inboxFolder.displayName,
                  folderId: inboxFolder.id,
                });
                initialFolderSet.current = true; // Mark the initial folder as set
              }
            }
          } else {
            console.error("Failed to fetch mail folders");
          }
        } catch (error) {
          console.error(
            "Error acquiring token or fetching mail folders",
            error
          );
        }
      }
    };

    if (isAuthenticated) {
      console.log("Fetching mail folders...");
      getMailFolders();
    }
  }, [instance, accounts, isAuthenticated]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="7"
          viewBox="0 0 9 7"
        >
          <path
            id="Polygon_60"
            data-name="Polygon 60"
            d="M3.659,1.308a1,1,0,0,1,1.682,0L8.01,5.459A1,1,0,0,1,7.168,7H1.832A1,1,0,0,1,.99,5.459Z"
            transform="translate(9 7) rotate(180)"
            fill="#707070"
          />
        </svg>
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "none",
      border: "none",
      minHeight: "21px",
      height: "21px",
      boxShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.18)",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "21px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "21px",
    }),
  };

  const [workOrders, setWorkOrders] = useState(
    Array.from({ length: 50 }, (_, index) => {
      const serial = String(index + 1).padStart(2, "0");
      return { value: `WONXF1JM${serial}`, label: `WONXF1JM${serial}` };
    })
  );

  const [poNumbers, setPoNumbers] = useState(
    Array.from({ length: 50 }, (_, index) => {
      const serial = String(index + 1).padStart(2, "0");
      return { value: `PONXF1JM${serial}`, label: `PONXF1JM${serial}` };
    })
  );

  const [selectedBuyer, setSelectedBuyer] = useState("");
  const toggleBuyerArea = (buyeName) => {
    setSelectedBuyer(buyeName);
  };
  return (
    <div
      className={
        props.resizeToggle ? "email-sidebar" : "email-sidebar expanded"
      }
    >
   
      <button
        onClick={() => {
          const newToggleValue = !props.resizeToggle;
          ls.set("resizeToggle", newToggleValue);
          props.setResizeToggle(newToggleValue);
        }}
        className="resizeToggle"
      >
        {props.resizeToggle ? (
          <i class="fa fa-angle-left"></i>
        ) : (
          <i class="fa fa-angle-right"></i>
        )}
      </button>
      <div className="email-section">
        <div className="folder_name">
          <i className="fa fa-sort-down"></i> Favorite
        </div>

        {isAuthenticated ? (
          <ul>
            {folders.length > 0 ? (
              folders.map((folder) => (
                <li key={folder.id}>
                  <button
                    className={
                      props.mailFolder.folderId === folder.id ? "active" : ""
                    }
                    onClick={() => {
                      props.setSelectedMailIds([]);
                      props.setMailFolder({
                        folderName: folder.displayName,
                        folderId: folder.id,
                      });
                    }}
                  >
                    {folder.displayName}
                    {folder.unreadItemCount > 0 && (
                      <span>{folder.unreadItemCount}</span>
                    )}
                  </button>
                </li>
              ))
            ) : (
              <p>No mail folders found.</p>
            )}
          </ul>
        ) : (
          <p>Please sign in to view your mail folders.</p>
        )}
      </div>
      <div className="email-section">
        <div className="folder_name">
          <i className="fa fa-sort-down"></i> WO-Wise
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={workOrders}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
      <div className="email-section">
        <div className="folder_name">
          <i className="fa fa-sort-down"></i> PO-Wise
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
      <div className="email-section">
        <div className="folder_name">
          <i className="fa fa-sort-down"></i> Sender
        </div>
        <ul>
          <li>
            <button
              className={selectedBuyer === "Next" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Next")}
            >
              {selectedBuyer === "Next" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Next</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Carmel" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Carmel")}
            >
              {selectedBuyer === "Carmel" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>

                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Carmel</span>
              )}
            </button>
          </li>
          <li>
            <button
              className={selectedBuyer === "Mango" ? "flex_button active" : ""}
              onClick={() => toggleBuyerArea("Mango")}
            >
              {selectedBuyer === "Mango" ? (
                <div className="search_button">
                  <div className="buyer_name">{selectedBuyer}</div>
                  <Select
                    className="select_wo"
                    placeholder="Search"
                    options={poNumbers}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              ) : (
                <span>Mango</span>
              )}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
