import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import swal from "sweetalert";

export default function MailDirection(props) {
 
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  // Memoize access token to avoid acquiring it unnecessarily
  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);

  const bulkDelete = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmDelete) => {
      if (confirmDelete) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: "deleteditems",
                }),
              }
            );

            if (moveResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error(
                "Failed to move email to Deleted Items for ID:",
                id
              );
            }
          } catch (error) {
            console.error("Error deleting email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);

  const bulkRestore = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to restore selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmRestore) => {
      if (confirmRestore) {
        const accessToken = await getAccessToken();
        let restoredFolder = null;

        // Check if "Restored" folder exists, create if necessary
        const folderCheckResponse = await fetch(
          `https://graph.microsoft.com/v1.0/me/mailFolders`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const foldersData = await folderCheckResponse.json();
        restoredFolder = foldersData.value.find(
          (folder) => folder.displayName === "Restored"
        );

        if (!restoredFolder) {
          const createFolderResponse = await fetch(
            `https://graph.microsoft.com/v1.0/me/mailFolders`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                displayName: "Restored",
              }),
            }
          );
          restoredFolder = await createFolderResponse.json();
        }

        for (const id of props.selectedMailIds) {
          try {
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: restoredFolder.id,
                }),
              }
            );

            if (moveResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to restore email ID:", id);
            }
          } catch (error) {
            console.error("Error restoring email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkPermanentDelete = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to permanently delete selected emails?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmDelete) => {
      if (confirmDelete) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const deleteResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (deleteResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to delete email ID:", id);
            }
          } catch (error) {
            console.error("Error deleting email ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkRead = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to mark selected emails as read?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmRead) => {
      if (confirmRead) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const readResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  isRead: true,
                }),
              }
            );

            if (readResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.map((email) =>
                  email.id === id ? { ...email, isRead: true } : email
                )
              );
            } else {
              console.error("Failed to mark email as read for ID:", id);
            }
          } catch (error) {
            console.error("Error marking email as read ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);
  const bulkUnread = useCallback(async () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to mark selected emails as unread?",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "Yes",
          value: true,
        },
      },
    }).then(async (confirmUnread) => {
      if (confirmUnread) {
        const accessToken = await getAccessToken();
        for (const id of props.selectedMailIds) {
          try {
            const unreadResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  isRead: false,
                }),
              }
            );

            if (unreadResponse.ok) {
              props.setSelectedMailIds([]);
              props.setEmails((prevEmails) =>
                prevEmails.map((email) =>
                  email.id === id ? { ...email, isRead: false } : email
                )
              );
            } else {
              console.error("Failed to mark email as unread for ID:", id);
            }
          } catch (error) {
            console.error("Error marking email as unread ID:", id, error);
          }
        }
      }
    });
  }, [getAccessToken, props.selectedMailIds]);

  const CancelDirection = () => {
    props.setSelectedMailIds([]);
  };

  return (
    <div
      style={{ position: "relative", width: "100%", height: "100%" }}
      className="mail_direction text-center"
    >
      <div div className="floating_part">
        {/* {props.selectedMailIds.length > 0 ? (
          <img
            src={require("../../assets/images/icons/selected.svg").default}
            alt="Logo"
          />
        ) : (
          <img
            src={require("../../assets/images/icons/light.svg").default}
            alt="Logo"
          />
        )} */}

        <div>
          <b>
            {props.selectedMailIds.length > 0
              ? props.selectedMailIds.length + " Item's selected"
              : "Select an item to read"}
          </b>
        </div>
        {props.selectedMailIds.length > 0 ? (
          <ul>
            {props.mailFolder.folderName === "Deleted Items" ? (
              <>
                <li>
                  <Link onClick={bulkRestore}>
                    <i class="fa fa-recycle text-success"></i>Restore
                  </Link>
                </li>
                <li>
                  <Link onClick={bulkPermanentDelete}>
                    <i class="fa fa-trash text-danger"></i>Permanently Delete
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link onClick={bulkDelete}>
                  <i class="fa fa-trash text-danger"></i>Delete
                </Link>
              </li>
            )}

            <li>
              <Link onClick={bulkRead}>
                <i class="fas fa-envelope-open"></i>Mark as read
              </Link>
            </li>

            <li>
              <Link onClick={bulkUnread}>
                <i class="fas fa-envelope"></i>Mark as unread
              </Link>
            </li>
            <li>
              <Link onClick={CancelDirection}>
                <i class="fas fa-times text-danger"></i>Cancel
              </Link>
            </li>
          </ul>
        ) : (
          <div className="text-muted">Nothing is selected</div>
        )}
      </div>
    </div>
  );
}
