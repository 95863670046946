import React, { forwardRef, useEffect } from 'react';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const Editor = forwardRef(({ content, onContentChange }, ref) => {
  useEffect(() => {
    // Store the Quill instance in the global scope
    window.quill = ref.current.getEditor();
  }, [ref]);

  const modules = {
    toolbar: false, // Disable default toolbar as we are using a custom one
  };

  return (
    <ReactQuill
      ref={ref}
      theme="snow"
      modules={modules}
      value={content}
      onChange={onContentChange}
      placeholder=""
      className="editor"
    />
  );
});

export default Editor;
