import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import Dropdown from "react-bootstrap/Dropdown";
import swal from "sweetalert";

export default function MailList(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [markAble, setMarkAble] = useState(false); // Define markAble state
  const [filterAble, setFilterAble] = useState(false); // Define filterAble state
  const [page, setPage] = useState(1);
  const inboxRef = useRef(null);
  const [filter, setFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("");

  // Memoize access token to avoid acquiring it unnecessarily
  const getAccessToken = useCallback(async () => {
    if (accounts.length > 0) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      return response.accessToken;
    }
  }, [instance, accounts]);

  // Fetch props.emails
  const getEmails = useCallback(
    async (pageNumber) => {
      if (accounts.length > 0 && props.mailFolder?.folderId) {
        try {
          const accessToken = await getAccessToken();

          let filterQuery = "";
          let sortQuery = "";

          // Handle filters
          if (filter === "unread") {
            filterQuery = `&$filter=isRead eq false`;
          } else if (filter === "toMe") {
            const email = accounts[0].username.toLowerCase();
            filterQuery = `&$filter=toRecipients/any(r:r/emailAddress/address eq '${email}')`;
          } else if (filter === "flagged") {
            filterQuery = `&$filter=flag/flagStatus eq 'flagged'`;
          } else if (filter === "hasFiles") {
            filterQuery = `&$filter=hasAttachments eq true`;
          } else if (filter === "mentionsMe") {
            const email = accounts[0].username.toLowerCase();
            filterQuery = `&$filter=body/content/contains('${email}')`;
          }

          // Handle sorting
          switch (sortOrder) {
            case "date":
              sortQuery = `$orderby=receivedDateTime asc`;
              break;
            case "from":
              sortQuery = `$orderby=from/emailAddress/name asc`;
              break;
            case "subject":
              sortQuery = `$orderby=subject asc`;
              break;
            default:
              sortQuery = `$orderby=receivedDateTime desc`;
          }

          const nextLink = `https://graph.microsoft.com/v1.0/me/mailFolders/${
            props.mailFolder.folderId
          }/messages?${sortQuery}&$top=50&$skip=${
            (pageNumber - 1) * 50
          }${filterQuery}`;

          // Build the Graph API URL with filter and pagination
          // const nextLink = `https://graph.microsoft.com/v1.0/me/mailFolders/${
          //   props.mailFolder.folderId
          // }/messages?$orderby=receivedDateTime desc&$top=50&$skip=${
          //   (pageNumber - 1) * 50
          // }${filterQuery}`;

          // const nextLink = `https://graph.microsoft.com/v1.0/me/mailFolders/${
          //   props.mailFolder.folderId
          // }/messages?$orderby=receivedDateTime desc&$top=50&$skip=${
          //   (pageNumber - 1) * 50
          // }`;

          const emailResponse = await fetch(nextLink, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (emailResponse.ok) {
            const data = await emailResponse.json();
            props.setEmails((prevEmails) => [...prevEmails, ...data.value]); // Append new props.emails
            setHasMore(data.value.length > 0); // Check if more props.emails are available
          } else {
            console.error("Failed to fetch Email:", emailResponse.statusText);
          }
        } catch (error) {
          console.error("Error acquiring token or fetching Emails", error);
        }
      }
    },
    [accounts, getAccessToken, props.mailFolder?.folderId, filter, sortOrder]
  );

  // Initial load and email reset when folder changes
  useEffect(() => {
    if (isAuthenticated && props.mailFolder?.folderId) {
      props.setEmails([]); // Reset props.emails when folderId changes
      setPage(1); // Reset page to 1
      setHasMore(true); // Reset hasMore
      getEmails(1); // Fetch initial props.emails
    }
  }, [isAuthenticated, props.mailFolder?.folderId, getEmails]);

  // Infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        inboxRef.current &&
        inboxRef.current.scrollTop + inboxRef.current.clientHeight >=
          inboxRef.current.scrollHeight - 100 &&
        !loadingMore &&
        hasMore
      ) {
        setLoadingMore(true);
        setPage((prevPage) => prevPage + 1); // Increment page to fetch more props.emails
      }
    };

    const inboxElement = inboxRef.current;
    if (inboxElement) {
      inboxElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (inboxElement) {
        inboxElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadingMore, hasMore]);

  useEffect(() => {
    if (loadingMore) {
      getEmails(page);
      setLoadingMore(false); // Reset loadingMore after fetching
    }
  }, [loadingMore, page, getEmails]);

  // Date formatting
  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const isSameYear = date.getFullYear() === now.getFullYear();

    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

    if (isToday) {
      return date.toLocaleTimeString([], timeOptions); // If today, show time
    } else if (isSameYear) {
      const dateOptions = { month: "numeric", day: "numeric" };
      return `${date.toLocaleDateString(
        [],
        dateOptions
      )} ${date.toLocaleTimeString([], timeOptions)}`;
    } else {
      const dateOptions = { month: "numeric", day: "numeric", year: "2-digit" };
      return `${date.toLocaleDateString(
        [],
        dateOptions
      )} ${date.toLocaleTimeString([], timeOptions)}`;
    }
  }, []);

  // Memoized email IDs to avoid recalculating during rendering
  const allEmailIds = useMemo(
    () => props.emails.map((email) => email.id),
    [props.emails]
  );

  const toggleMarkAble = useCallback(() => {
    props.setSelectedMailIds([]);
    setMarkAble((prevMarkAble) => !prevMarkAble);
  }, [props]);

  const toggleSelectAll = useCallback(() => {
    if (props.selectedMailIds.length === props.emails.length) {
      props.setSelectedMailIds([]); // Unselect all
    } else {
      props.setSelectedMailIds(allEmailIds); // Select all
    }
  }, [props.emails.length, props, allEmailIds]);

  const toggleSelectChange = useCallback(
    (id) => {
      if (props.selectedMailIds.includes(id)) {
        props.setSelectedMailIds(
          props.selectedMailIds.filter((selectedId) => selectedId !== id)
        ); // Unselect item
      } else {
        props.setSelectedMailIds([...props.selectedMailIds, id]); // Select item
      }
    },
    [props]
  );

  const filterToggle = () => {
    setFilterAble(!filterAble);
  };

  const handleDelete = useCallback(
    async (id) => {
      swal({
        title: "Are you sure?",
        text: "Do you want to delete this email?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: {
            text: "Yes",
            value: true,
          },
        },
      }).then(async (confirmDelete) => {
        if (confirmDelete) {
          try {
            const accessToken = await getAccessToken();
            // Move email to Deleted Items folder
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: "deleteditems", // Use the correct ID for the Deleted Items folder
                }),
              }
            );

            if (moveResponse.ok) {
              // Update local state to remove the email immediately
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to move email to Deleted Items");
            }
          } catch (error) {
            console.error("Error deleting email:", error);
          }
        }
      });
    },
    [getAccessToken]
  );

  const handleRestore = useCallback(
    async (id) => {
      swal({
        title: "Are you sure?",
        text: "Do you want to restore this email?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: {
            text: "Yes",
            value: true,
          },
        },
      }).then(async (confirmRestore) => {
        if (confirmRestore) {
          try {
            const accessToken = await getAccessToken();

            // Step 1: Check if "Restored" folder exists
            const folderCheckResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/mailFolders`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            const foldersData = await folderCheckResponse.json();
            let restoredFolder = foldersData.value.find(
              (folder) => folder.displayName === "Restored"
            );

            // Step 2: If "Restored" folder does not exist, create it
            if (!restoredFolder) {
              const createFolderResponse = await fetch(
                `https://graph.microsoft.com/v1.0/me/mailFolders`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    displayName: "Restored",
                  }),
                }
              );
              restoredFolder = await createFolderResponse.json();
            }

            // Step 3: Move the email to the "Restored" folder
            const moveResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}/move`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  destinationId: restoredFolder.id,
                }),
              }
            );

            const responseData = await moveResponse.json(); // Log response data
            if (moveResponse.ok) {
              console.log("Email restored successfully", responseData);
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to restore email");
            }
          } catch (error) {
            console.error("Error restoring email:", error);
          }
        }
      });
    },
    [getAccessToken, getEmails] // Include getEmails in dependencies if it's defined outside
  );

  const handlePermanentDelete = useCallback(
    async (id) => {
      swal({
        title: "Are you sure?",
        text: "Do you want to Permanent delete this email?",
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: {
            text: "Yes",
            value: true,
          },
        },
      }).then(async (confirmDelete) => {
        if (confirmDelete) {
          try {
            const accessToken = await getAccessToken();
            // Move email to Deleted Items folder
            const deleteResponse = await fetch(
              `https://graph.microsoft.com/v1.0/me/messages/${id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (deleteResponse.ok) {
              props.setEmails((prevEmails) =>
                prevEmails.filter((email) => email.id !== id)
              );
            } else {
              console.error("Failed to move email to Deleted Items");
            }
          } catch (error) {
            console.error("Error deleting email:", error);
          }
        }
      });
    },
    [getAccessToken]
  );

  const toggleFlag = useCallback(
    async (id, currentFlagStatus) => {
      try {
        const accessToken = await getAccessToken();

        // Determine the new flag status
        let newFlagStatus;
        if (currentFlagStatus === "notFlagged") {
          newFlagStatus = "flagged"; // Flag it
        } else if (currentFlagStatus === "flagged") {
          newFlagStatus = "complete"; // Mark as complete
        } else {
          newFlagStatus = "notFlagged"; // Unflag it
        }

        const response = await fetch(
          `https://graph.microsoft.com/v1.0/me/messages/${id}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              flag: {
                flagStatus: newFlagStatus,
              },
            }),
          }
        );

        if (response.ok) {
          props.setEmails((prevEmails) =>
            prevEmails.map((email) =>
              email.id === id
                ? { ...email, flag: { flagStatus: newFlagStatus } }
                : email
            )
          ); // Update the flag status locally
        } else {
          console.error("Failed to toggle flag:", response.statusText);
        }
      } catch (error) {
        console.error("Error toggling flag:", error);
      }
    },
    [getAccessToken, props.setEmails]
  );

  return (
    <div className={`inbox ${props.extendDetailsToggle ? "collapsed" : ""}`}>
      <div className="inbox-header">
        <div className="inbox_header_left">
          <div className="title">
            {markAble && (
              <>
                <input
                  onClick={toggleSelectAll}
                  type="checkbox"
                  checked={props.selectedMailIds.length === props.emails.length}
                />{" "}
              </>
            )}
            {props.mailFolder.folderName}
          </div>
          <div className="buttons_group">
            <button onClick={() => setFilter("all")}>All</button>
            <button onClick={() => setFilter("unread")}>Unread</button>
            <button onClick={() => setFilter("toMe")}>To me</button>
          </div>
        </div>
        <div className="inbox_header_left">
          {props.emails.length > 0 && (
            <span
              className="toggleSelect"
              style={{ cursor: "pointer" }}
              onClick={toggleMarkAble}
            >
              {markAble ? (
                <img
                  style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                  src={require("../../assets/images/icons/T1-O.png").default}
                  alt="Logo"
                />
              ) : (
                <img
                  style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                  src={require("../../assets/images/icons/T1-W.png").default}
                  alt="Logo"
                />
              )}
            </span>
          )}

          <Dropdown
            className="mail_filter_dropdown"
            onClick={filterToggle}
            // data-bs-theme="dark"
          >
            <Dropdown.Toggle
              id="dropdown-button-dark-example1"
              variant="secondary"
            >
              {filterAble ? (
                <img
                  style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                  src={
                    require("../../assets/images/icons/Settings-O.png").default
                  }
                  alt="Logo"
                />
              ) : (
                <img
                  style={{ height: "22px", width: "22px" }} // Corrected 'widows' to 'width'
                  src={
                    require("../../assets/images/icons/Settings-W.png").default
                  }
                  alt="Logo"
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setSortOrder("date")}>
                Date
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSortOrder("from")}>
                From
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setSortOrder("subject")}>
                Subject
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <ul className="list-group" ref={inboxRef}>
        {Array.isArray(props.emails) && props.emails.length > 0 ? (
          props.emails.map((item, index) => (
            <li
              key={item.id}
              className={
                props.mailID === item.id
                  ? "list-group-item mailing_single active"
                  : "list-group-item mailing_single"
              }
            >
              <div
                title={item.subject}
                className={
                  item.isRead === false ? "mail_text unread" : "mail_text"
                }
              >
                {markAble ? (
                  <>
                    <input
                      onChange={() => toggleSelectChange(item.id)}
                      type="checkbox"
                      checked={props.selectedMailIds.includes(item.id)}
                    />{" "}
                  </>
                ) : (
                  <i className="fa fa-play"></i>
                )}
                <span
                  onClick={() => {
                    props.setMailID(item.id);
                    props.setIsComposing(false);
                  }}
                >
                  {item.subject ? item.subject : "No Subject"}
                </span>
              </div>
              <div
                onClick={() => {
                  props.setMailID(item.id);
                  props.setIsComposing(false);
                }}
                title={item.sender?.emailAddress?.name}
                className="mail_text"
              >
                <span className="step_border"></span>
                {item.sender?.emailAddress?.name}
              </div>
              <div
                onClick={() => {
                  props.setMailID(item.id);
                  props.setIsComposing(false);
                }}
                title="wo001"
                className="mail_text"
              >
                <span className="step_border"></span>wo001
              </div>
              <div
                title={formatDate(item.receivedDateTime)}
                className="mail_text dateTime"
              >
                <span className="step_border"></span>

                <div className="hide_when_hover">
                  {" "}
                  {formatDate(item.receivedDateTime)}
                </div>
                <div className="show_when_hover">
                  {item.flag?.flagStatus === "notFlagged" ? (
                    <i
                      onClick={() => toggleFlag(item.id, item.flag.flagStatus)}
                      className="fa fa-flag me-1"
                    ></i>
                  ) : item.flag?.flagStatus === "flagged" ? (
                    <i
                      onClick={() => toggleFlag(item.id, item.flag.flagStatus)}
                      className="fa fa-pause me-1"
                    ></i>
                  ) : (
                    <i
                      onClick={() => toggleFlag(item.id, item.flag.flagStatus)}
                      className="fa fa-check me-1 text-success"
                    ></i>
                  )}

                  {props.mailFolder.folderName === "Deleted Items" ? (
                    <>
                      <i
                        onClick={() => handleRestore(item.id)}
                        className="fa fa-recycle text-success"
                      ></i>

                      <i
                        onClick={() => handlePermanentDelete(item.id)}
                        className="fa fa-trash text-danger"
                      ></i>
                    </>
                  ) : (
                    <i
                      onClick={() => handleDelete(item.id)}
                      className="fa fa-trash text-danger"
                    ></i>
                  )}
                </div>
              </div>
            </li>
          ))
        ) : (
          <li>No emails found or failed to load emails</li>
        )}
      </ul>
    </div>
  );
}
