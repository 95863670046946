import React, { useState, useContext } from "react";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import { Link, useLocation } from "react-router-dom";
import { hasPermission } from "../routes/permissions/CheckPermissions";
import { defaultConfig } from "../routes/permissions/PermissionsConfig";
import ls from "services/ls";

export default function AdminSidebar(props) {
  const { userData, isAuthenticated } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const { updateUserObj } = useContext(AppContext);
  const themeMode = "bg_light";

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  return (
    <div className={`sidebar ${themeMode}`}>
      <nav className={`navbar ${themeMode}`} style={{ display: "block" }}>
        <div className="fixed_area">
          <div className="logo_area">
            <a
              href="/dashboard"
              className="navbar-brand margin-auto text-center"
            >
              <img
                src={require("../assets/images/logos/logo-short.png").default}
                alt="Logo"
              />
            </a>
          </div>
          {pathname === "/mailbox" ? (
            <>
              {props.resizeToggle ? (
                ""
              ) : (
                <button
                  onClick={() => {
                    const newToggleValue = !props.resizeToggle;
                    ls.set("resizeToggle", newToggleValue);
                    props.setResizeToggle(newToggleValue);
                  }}
                  className="resizeToggle sidebarMenu"
                >
                  {props.resizeToggle ? (
                    <i class="fa fa-angle-left"></i>
                  ) : (
                    <i class="fa fa-angle-right"></i>
                  )}
                </button>
              )}
            </>
          ) : (
            ""
          )}

          <div className="common_menus">
            <ul>
              <li>
                <Link
                  to="/mailbox"
                  className={pathname === "/mailbox" ? "active" : ""}
                >
                  <span className="icon_shadow">
                    <img
                      className="inactive"
                      src={require("../assets/images/icons/Mail-W.png").default}
                      alt="Logo"
                    />
                    <img
                      className="active"
                      src={require("../assets/images/icons/Mail-O.png").default}
                      alt="Logo"
                    />
                  </span>
                  Mail
                </Link>
              </li>
              <li>
                <Link
                  to="/schedules"
                  className={pathname === "/schedules" ? "active" : ""}
                >
                  <span className="icon_shadow">
                    <img
                      className="inactive"
                      src={
                        require("../assets/images/icons/Schedule-W.png").default
                      }
                      alt="Logo"
                    />
                    <img
                      className="active"
                      src={
                        require("../assets/images/icons/Schedule-O.png").default
                      }
                      alt="Logo"
                    />
                  </span>
                  Cal
                </Link>
              </li>
              <li>
                <Link
                  to="/tasks"
                  className={pathname === "/tasks" ? "active" : ""}
                >
                  <span className="icon_shadow">
                    <img
                      className="inactive"
                      src={require("../assets/images/icons/Task-W.png").default}
                      alt="Logo"
                    />
                    <img
                      className="active"
                      src={require("../assets/images/icons/Task-O.png").default}
                      alt="Logo"
                    />
                  </span>
                  Task
                </Link>
              </li>
              <li>
                <Link to="#">
                  <span className="icon_shadow">
                    <img
                      className="inactive"
                      src={require("../assets/images/icons/Work-W.png").default}
                      alt="Logo"
                    />
                    <img
                      className="active"
                      src={require("../assets/images/icons/Work-O.png").default}
                      alt="Logo"
                    />
                  </span>
                  Work
                </Link>
              </li>
              <li>
                <Link
                  to="/files"
                  className={pathname === "/files" ? "active" : ""}
                >
                  <span className="icon_shadow">
                    <img
                      className="inactive"
                      src={
                        require("../assets/images/icons/Files-W.png").default
                      }
                      alt="Logo"
                    />
                    <img
                      className="active"
                      src={
                        require("../assets/images/icons/Files-O.png").default
                      }
                      alt="Logo"
                    />
                  </span>
                  Files
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className="common_menus">
            <ul>
              {defaultConfig.map(
                (item, index) =>
                  hasPermission(userData, item.path) && (
                    <li key={index}>
                      <Link
                        to={item.path}
                        className={pathname === item.path ? "active" : ""}
                      >
                        {item.label}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          </div> */}
        </div>

        <div className="dynamic_area">
          <div className="permission_menus common_menus">
            <ul>
              <li>
                <Link
                  to="#"
                  className={openMenu === "Next" ? "active" : ""}
                  onClick={() => toggleMenu("Next")}
                >
                  Next
                </Link>
              </li>
              {openMenu === "Next" && (
                <>
                  <li>
                    <Link to="#">Pd</Link>
                  </li>
                  <li>
                    <Link to="/work-orders">W/O</Link>
                  </li>
                  <li>
                    <Link to="#">T&A</Link>
                  </li>
                </>
              )}
            </ul>

            <ul>
              <li>
                <Link
                  to="#"
                  className={openMenu === "Carmel" ? "active" : ""}
                  onClick={() => toggleMenu("Carmel")}
                >
                  Carmel
                </Link>
              </li>
              {openMenu === "Carmel" && (
                <>
                  <li>
                    <Link to="#">Pd</Link>
                  </li>
                  <li>
                    <Link to="#">W/O</Link>
                  </li>
                  <li>
                    <Link to="#">T&A</Link>
                  </li>
                </>
              )}
            </ul>

            <ul>
              <li>
                <Link
                  to="#"
                  className={openMenu === "Garan" ? "active" : ""}
                  onClick={() => toggleMenu("Garan")}
                >
                  Garan
                </Link>
              </li>
              {openMenu === "Garan" && (
                <>
                  <li>
                    <Link to="#">Pd</Link>
                  </li>
                  <li>
                    <Link to="#">W/O</Link>
                  </li>
                  <li>
                    <Link to="#">T&A</Link>
                  </li>
                </>
              )}
            </ul>

            <ul>
              <li>
                <Link
                  to="#"
                  className={openMenu === "Mango" ? "active" : ""}
                  onClick={() => toggleMenu("Mango")}
                >
                  Mango
                </Link>
              </li>
              {openMenu === "Mango" && (
                <>
                  <li>
                    <Link to="#">Pd</Link>
                  </li>
                  <li>
                    <Link to="#">W/O</Link>
                  </li>
                  <li>
                    <Link to="#">T&A</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="user_personal_menus">
          {openMenu === "Profile" && (
            <ul>
              <li>
                <Link
                  className={pathname === "/profile" ? "active" : ""}
                  to="/profile"
                  title="Profile"
                >
                  <i className="fa fa-user"></i>
                </Link>
              </li>
              <li>
                <Link title="Log Out" to="#" onClick={logout}>
                  <i className="fa fa-power-off"></i>
                </Link>
              </li>
            </ul>
          )}

          <img
            onClick={() => toggleMenu("Profile")}
            style={{ cursor: "pointer" }}
            className="profile_image_menu"
            src={props.userData?.profile_picture}
            alt=""
          />
        </div>
      </nav>
    </div>
  );
}
