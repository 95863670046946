import React, { useState } from "react";
import { Dropdown, DropdownButton, Modal, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";

export default function MailChain(props) {
  const [activeKey, setActiveKey] = useState(null);
  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredEmails, setHoveredEmails] = useState([]);

  // Attachment Functionalities
  const handleDownload = (attachment) => {
    const link = document.createElement("a");
    link.href = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
    link.download = attachment.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreview = (attachment) => {
    // Implement preview functionality
    console.log("Preview:", attachment.name);
  };

  const handleSaveToOneDrive = (attachment) => {
    // Implement save to OneDrive functionality
    console.log("Save to OneDrive:", attachment.name);
  };

  const handleCopy = (attachment) => {
    // Implement copy functionality
    console.log("Copy:", attachment.name);
  };

  const handleClick = (emails) => {
    setHoveredEmails(emails);
    setIsModalOpen(true);
  };

  const renderEmails = (emails) => {
    if (!emails || emails.trim() === "") {
      return null;
    }

    const emailArray = emails.split(", ");
    return (
      <>
        {emailArray.slice(0, 2).map((email, index) => (
          <span key={index}>
            {email}
            {index < 1 && emailArray.length > 2 ? ", " : ""}
          </span>
        ))}
        {emailArray.length > 2 && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleClick(emailArray)}
          >
            {" ..."}more
          </span>
        )}
      </>
    );
  };
  return (
    <div className="mailChain">
      <Accordion
        className="mailchain_accordion mail_body_area"
        defaultActiveKey={props.mailID}
      >
        {props.chainMessages.length > 0 &&
          props.chainMessages.map((message, index) => (
            <Accordion.Item eventKey={message.id} key={message.id}>
              <Accordion.Header
                onClick={() => handleAccordionToggle(message.id)}
              >
                <div className="mail_header">
                  <div className="users_area">
                    <div className="thumb">
                      <img
                        width={22}
                        src={
                          require("../../assets/images/logos/notification.png")
                            .default
                        }
                        alt="Logo"
                      />
                    </div>
                    <div className="user_details">
                      <div className="username">
                        {message.from?.emailAddress?.name || "Unknown"}
                      </div>

                      {activeKey === message.id && (
                        <>
                          <div className="toString">
                            To:{" "}
                            {renderEmails(
                              message.toRecipients
                                ?.map(
                                  (recipient) => recipient.emailAddress.address
                                )
                                .join(", ")
                            )}
                          </div>
                          <div className="toString">
                            CC:{" "}
                            {renderEmails(
                              message.ccRecipients
                                ?.map(
                                  (recipient) => recipient.emailAddress.address
                                )
                                .join(", ")
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="extranal_btns">
                    {activeKey === message.id ? (
                      <div className="button_grups">
                        <button>Reply</button>
                        <button>Reply All</button>
                        <button>Forward</button>
                      </div>
                    ) : (
                      <div style={{ fontSize: "12px" }}>
                        {moment(message.receivedDateTime).format(
                          "MMM D, YYYY [at] h:mm A"
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="mail_body_area">
                  {message?.attachments && message.attachments.length > 0 && (
                    <div className="attachments">
                      {message.attachments
                        .filter((attachment) => !attachment.isInline)
                        .map((attachment, index) => (
                          <div key={index} className="single_attachment">
                            <a
                              href={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
                              download={attachment.name}
                            >
                              {attachment.name.endsWith("png") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-image"
                                ></i>
                              ) : attachment.name.endsWith("jpg") ||
                                attachment.name.endsWith("jpeg") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file-image"
                                ></i>
                              ) : attachment.name.endsWith("pdf") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file-pdf"
                                ></i>
                              ) : attachment.name.endsWith("doc") ||
                                attachment.name.endsWith("docx") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file-word"
                                ></i>
                              ) : attachment.name.endsWith("xls") ||
                                attachment.name.endsWith("xlsx") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file-excel"
                                ></i>
                              ) : attachment.name.endsWith("ppt") ||
                                attachment.name.endsWith("pptx") ? (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file-powerpoint"
                                ></i>
                              ) : (
                                <i
                                  style={{
                                    fontSize: "14px",
                                    marginRight: "5px",
                                  }}
                                  className="fa fa-file"
                                ></i>
                              )}
                              {attachment.name}
                            </a>
                            <DropdownButton
                              id={`dropdown-${index}`}
                              title={<i className="fa fa-angle-down" />}
                              variant="secondary"
                              className="dropdown_toggle"
                            >
                              <Dropdown.Item
                                onClick={() => handlePreview(attachment)}
                              >
                                Preview
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleSaveToOneDrive(attachment)}
                              >
                                Save to OneDrive
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleCopy(attachment)}
                              >
                                Copy
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDownload(attachment)}
                              >
                                Download
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        ))}
                    </div>
                  )}
                  <div
                    style={{ width: "100%", overflowY: "hidden" }}
                    className="email_textarea_body"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: message.body?.content || "",
                      }}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>All Emails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {hoveredEmails.map((email, index) => (
            <div key={index}>{email}</div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
