import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "services/authConfig";
import 'react-quill/dist/quill.snow.css'; // Import styles

export default function SingleMailDetails(props) {
  //MAIL READ FUNCTION
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (props.selectedMail && !props.selectedMail.isRead) {
      const timer = setTimeout(() => {
        markAsRead();
      }, 5000); //

      return () => clearTimeout(timer);
    }
  }, [props.selectedMail]);

  const markAsRead = async () => {
    if (!props.selectedMail) return;

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      const accessToken = response.accessToken;
      const selectedMailId = props.selectedMail.id;

      const updateResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/messages/${selectedMailId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isRead: true,
          }),
        }
      );

      if (updateResponse.ok) {
        props.selectedMail.isRead = true;
      } else {
        console.error("Failed to mark email as read");
      }
    } catch (error) {
      console.error("Error marking email as read", error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredEmails, setHoveredEmails] = useState([]);

  // Attachment Functionalities
  const handleDownload = (attachment) => {
    const link = document.createElement("a");
    link.href = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
    link.download = attachment.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreview = (attachment) => {
    // Implement preview functionality
    console.log("Preview:", attachment.name);
  };

  const handleSaveToOneDrive = (attachment) => {
    // Implement save to OneDrive functionality
    console.log("Save to OneDrive:", attachment.name);
  };

  const handleCopy = (attachment) => {
    // Implement copy functionality
    console.log("Copy:", attachment.name);
  };

  const handleClick = (emails) => {
    setHoveredEmails(emails);
    setIsModalOpen(true);
  };

  const renderEmails = (emails) => {
    if (!emails || emails.trim() === "") {
      return null;
    }

    const emailArray = emails.split(", ");
    return (
      <>
        {emailArray.slice(0, 2).map((email, index) => (
          <span key={index}>
            {email}
            {index < 1 && emailArray.length > 2 ? ", " : ""}
          </span>
        ))}
        {emailArray.length > 2 && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => handleClick(emailArray)}
          >
            {" ..."}more
          </span>
        )}
      </>
    );
  };

  return (
    <>
      {props.selectedMail.id && (
        <>
          <div className="mail_body_area">
            <div className="mail_header">
              <div className="users_area">
                <div className="thumb">
                  <img
                    width={22}
                    src={
                      require("../../assets/images/logos/notification.png")
                        .default
                    }
                    alt="Logo"
                  />
                </div>

                <div className="user_details">
                  <div className="username">
                    {props.selectedMail.from?.emailAddress?.name ||
                      props.selectedMail.from?.emailAddress?.address}
                  </div>
                  <div className="toString">
                    To:{" "}
                    {renderEmails(
                      props.selectedMail.toRecipients
                        ?.map(
                          (recipient) =>
                            recipient.emailAddress?.name ||
                            recipient.emailAddress?.address
                        )
                        .join(", ")
                    )}
                  </div>
                  <div className="toString">
                    CC:{" "}
                    {renderEmails(
                      props.selectedMail.ccRecipients
                        ?.map(
                          (recipient) =>
                            recipient.emailAddress?.name ||
                            recipient.emailAddress?.address
                        )
                        .join(", ")
                    )}
                  </div>
                </div>
              </div>
              {props.replyble ? (
                <div className="extranal_btns">
                  <div className="button_grups">
                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("Reply");
                      }}
                    >
                      Reply
                    </button>

                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("ReplyAll");
                      }}
                    >
                      Reply All
                    </button>
                    <button
                      onClick={() => {
                        props.setIsComposing(true);
                        props.setMailSendType("Forward");
                      }}
                    >
                      Forward
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ fontSize: "12px" }}>
                  {moment(props.receivedDateTime).format(
                    "MMM D, YYYY [at] h:mm A"
                  )}
                </div>
              )}
            </div>
            <hr />
            {props.selectedMail?.attachments &&
              props.selectedMail.attachments.length > 0 && (
                <div className="attachments">
                  {props.selectedMail.attachments
                    .filter((attachment) => !attachment.isInline)
                    .map((attachment, index) => (
                      <div key={index} className="single_attachment">
                        <a
                          href={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
                          download={attachment.name}
                        >
                          {attachment.name.endsWith("png") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-image"
                            ></i>
                          ) : attachment.name.endsWith("jpg") ||
                            attachment.name.endsWith("jpeg") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-image"
                            ></i>
                          ) : attachment.name.endsWith("pdf") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-pdf"
                            ></i>
                          ) : attachment.name.endsWith("doc") ||
                            attachment.name.endsWith("docx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-word"
                            ></i>
                          ) : attachment.name.endsWith("xls") ||
                            attachment.name.endsWith("xlsx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-excel"
                            ></i>
                          ) : attachment.name.endsWith("ppt") ||
                            attachment.name.endsWith("pptx") ? (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file-powerpoint"
                            ></i>
                          ) : (
                            <i
                              style={{
                                fontSize: "14px",
                                marginRight: "5px",
                              }}
                              className="fa fa-file"
                            ></i>
                          )}
                          {attachment.name}
                        </a>
                        <DropdownButton
                          id={`dropdown-${index}`}
                          title={<i className="fa fa-angle-down" />}
                          variant="secondary"
                          className="dropdown_toggle"
                        >
                          <Dropdown.Item
                            onClick={() => handlePreview(attachment)}
                          >
                            Preview
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleSaveToOneDrive(attachment)}
                          >
                            Save to OneDrive
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCopy(attachment)}>
                            Copy
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDownload(attachment)}
                          >
                            Download
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    ))}
                </div>
              )}
            <div
              style={{ width: "100%", overflowY: "hidden" }}
              className="email_textarea_body"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.selectedMail.body?.content,
                }}
              />
            </div>
          </div>
          <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
            <Modal.Header closeButton>
              <Modal.Title>All Emails</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {hoveredEmails.map((email, index) => (
                <div key={index}>{email}</div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}
